import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store/';
import { KeyValuePair, SelectOption } from '../models/Common';
import { Calendar as CalendarModel, CourseInfo, Offering } from '../models/Calendar';
import { SiteState, siteSlice } from '../store/Site';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import {
    Box, Text, Layer, Accordion, AccordionPanel, Tip, Paragraph, ResponsiveContext, Heading, DropButton,
    CheckBox, TextInput, Grid, DateInput, Button, Table, TableBody, TableRow, TableCell, Tabs, Tab, Card, CardBody, CardFooter, Select
} from 'grommet';
import { retrieveLocalizedStrings } from '../store/Site';
import { retrieveOptions, retrieveCourses, calendarSlice } from '../store/Calendar';
import _ from 'lodash';
import BasePage from '../components/BasePage';
import CourseCard from '../components/CourseCard';
import parse from 'html-react-parser';
import { formatString, updateList } from '../Utilities';
import { Close, Search } from 'grommet-icons';
import { format } from 'date-fns';
import queryString from 'query-string';
import { CardHeader } from 'grommet';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    retrieveOptions,
    retrieveCourses,
    ...siteSlice.actions,
    ...calendarSlice.actions
}

interface ICalendarProps {
    data: CalendarModel;
    site: SiteState;
}

interface CalendarState {
    offeringView: JSX.Element | undefined;
    showOfferings: boolean;
    profile: any;
    filtersSet: boolean;
}

type CalendarProps = ICalendarProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Calendar extends React.PureComponent<CalendarProps, CalendarState> {
    constructor(props: CalendarProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : null
            : null;
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Datacard.Training_Label', 'ExternalPages.Narrow_by_Label',
            'ExternalPages.CalendarView_Label', 'ExternalPages.Regions_Label', 'ExternalPages.CourseType_Label', 'ExternalPages.No_Matching_Offerings_Statement',
            'ExternalPages.TrainingMode_Label', 'ExternalPages.DeliveryLanguage_Label', 'ExternalPages.JobRole_Label',
            'ExternalPages.Technologies_Label', 'ExternalPages.TrainingCompany_Label', 'ExternalPages.TrainingCalendar_Introduction_Statement',
            'ExternalPages.Additional_Events_Link', 'ExternalPages.TrainingCalendar_Info_Statement', 'ExternalPages.GroupBy_Label',
            'ExternalPages.Location_Label', 'ExternalPages.Language_Label', 'ExternalPages.Start_Label', 'ExternalPages.End_Label',
            'ExternalPages.Duration_Label', 'ExternalPages.Link_Label', 'ExternalPages.Region_Label', 'ExternalPages.OfferingID_Label',
            'Datacard.Course_Id_Label', 'ExternalPages.VendorCourseID_Label', 'ExternalPages.CourseDates_Label', 'ExternalPages.Address_Label',
            'ExternalPages.Guaranteed_To_Run', 'Datacard.Register_Label', 'ExternalPages.Apply_NarrowBy_Label', 'ExternalPages.Apply_Filters_Label',
            'ExternalPages.Reset_Filters_Label', 'Metadata.TrainingCalendarKeywords', 'Metadata.TrainingCalendarDescription', 'ExternalPages.FilterBy_Label',
            'ExternalPages.Date_Range_Label', 'ExternalPages.TrainingCalendar_Search_Placeolder', 'ExternalPages.DeliveryFormats_Label',
            'ExternalPages.Guaranteed_To_Run_Statement', 'ExternalPages.Customer_Label', 'ExternalPages.Hpe_Employee_Label', 'ExternalPages.Hpe_Partner_Label',
            'ExternalPages.TainingCalender_MaseOffiing_Statement'];

        this.state = {
            offeringView: undefined,
            showOfferings: false,
            profile: { text: "HPE partner​", value: "partner" },
            filtersSet: false
        };

        this.props.retrieveLocalizedStrings(keys, urlLanguage);
        this.props.retrieveOptions(urlLanguage);
        this.props.setActiveMenu('training-menu');
    }

    public componentDidMount() {
    }

    public componentDidUpdate(prevProps: CalendarProps) {
        let site = this.props.site;
        let currentLang = site.currentLanguage;
        let langInfo = currentLang.value.split('-')
        let previousLang = prevProps.site.currentLanguage;
        let calendar = this.props.calendar;
        let options = { ...calendar.options };
        let query = queryString.parse(window.location.search);
        let queryKeys = Object.keys(query);
        let setOptions = queryKeys.length !== 0;

        if (currentLang.value !== previousLang.value && !site.loading) {
            this.props.retrieveOptions(langInfo[0]);
            this.setState({ filtersSet: false });
        }
        if (!this.state.filtersSet && (options.regions.length > 1)) {
            queryKeys.forEach((key, ndx) => {
                let caseKey = key.toLowerCase();

                switch (caseKey) {
                    case 'courseid':
                        {
                            this.props.setCourseId(query[key]);
                            break;
                        }
                    case 'excludepartners':
                        {
                            let excludePartners = JSON.parse(String(query[key]));

                            this.props.setExcludePartnersRestricted(excludePartners);
                            this.setState({ profile: excludePartners ? { text: "Customer/HPE Employee​", value: "customer" } : { text: "HPE partner​", value: "partner" } })
                            break;
                        }
                    case 'technologies':
                        {
                            let urlTechnologies = Array.isArray(query[key]) ? Array.from(query[key] as string[]) : String(query[key]).split(',');
                            let technologies = updateList(options.technologies, 0, false, true);

                            urlTechnologies.forEach((format) => {
                                let option = technologies.find((cf: SelectOption) => cf.value.toLowerCase().indexOf(format.toLowerCase()) !== -1);

                                if (option) {
                                    option.selected = true;
                                }
                            });
                            options.technologies = technologies;
                            break;
                        }
                    case 'formats':
                        {
                            let deliveryFormats = Array.isArray(query[key]) ? Array.from(query[key] as string[]) : String(query[key]).split(',');
                            let modes = updateList(options.trainingModes, 0, false, true);

                            deliveryFormats.forEach((format) => {
                                let option = modes.find((cf: SelectOption) => cf.value.toLowerCase().indexOf(format.toLowerCase()) !== -1);

                                if (option) {
                                    option.selected = true;
                                }
                            });
                            options.trainingModes = modes;
                            break;
                        }
                    case 'trainingcompany':
                        {
                            let companies = Array.isArray(query[key]) ? Array.from(query[key] as string[]) : String(query[key]).split(',');
                            let trainingCompanies = updateList(options.trainingCompanies, 0, false, true);

                            companies.forEach((format) => {
                                let option = trainingCompanies.find((cf: SelectOption) => cf.value.toLowerCase().indexOf(format.toLowerCase()) !== -1);

                                if (option) {
                                    option.selected = true;
                                }
                            });
                            options.trainingCompanies = trainingCompanies;
                            break;
                        }
                    case 'selectlanguage':
                        {
                            let languages = Array.isArray(query[key]) ? Array.from(query[key] as string[]) : String(query[key]).split(',');
                            let deliveryLanguages = updateList(options.languages, 0, false, true);

                            languages.forEach((format) => {
                                let option = deliveryLanguages.find((cf: SelectOption) => cf.value.toLowerCase().indexOf(format.toLowerCase()) !== -1);

                                if (option) {
                                    option.selected = true;
                                }
                            });
                            options.languages = deliveryLanguages;
                            break;
                        }
                }
            })
            if (setOptions) {
                this.props.setOptions(options);
            }
            this.props.retrieveCourses(langInfo[0]);
            this.setState({ filtersSet: true });
        }
    }

    onCheckedChange = (event: any) => {
        this.props.setSelected(`${event.target.id}-${event.target.checked}`)
    }

    setEntityType = (event: any) => {
        this.props.setEntityType(event.target.value);
    }

    onlyUnique = (value: any, index: number, self: any[]) => {
        return self.indexOf(value) === index;
    }

    onStartDateChanged = (e: any) => {
        this.props.setStartDate(e.value);
    }

    onEndDateChanged = (e: any) => {
        this.props.setEndDate(e.value);
    }

    getOfferingTable = (offering: Offering) => {
        let site = this.props.site;
        let courseDates = offering.start && offering.end
            ? `${format(new Date(offering.start), 'dd-MMM-yyyy')} to ${format(new Date(offering.end), 'dd-MMM-yyyy')}`
            : null;

        return <Card key={`offering-box-${offering.id}`} pad="small" gap="small" height={{ min: "medium" }}>
            <CardBody fill gap="small" >
                <Table key={`offering-${offering.id}`}>
                    <TableBody>
                        <TableRow>
                            <TableCell pad={{ right: 'none' }} >
                                <Text weight='bold' size="small">{site.localizedStrings['Datacard.Course_Id_Label']}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{offering.courseNumber}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell pad={{ right: 'none' }} >
                                <Text weight='bold' size="small">{site.localizedStrings['ExternalPages.OfferingID_Label']}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{offering.id}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell pad={{ right: 'none' }} >
                                <Text weight='bold' size="small">{site.localizedStrings['ExternalPages.Region_Label']}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{offering.region}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell pad={{ right: 'none' }} >
                                <Text weight='bold' size="small">{site.localizedStrings['ExternalPages.Language_Label']}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{offering.language}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell pad={{ right: 'none' }} >
                                <Text weight='bold' size="small">{site.localizedStrings['ExternalPages.JobRole_Label']}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{offering.jobRole}</Text>
                            </TableCell>
                        </TableRow>
                        {offering.company && <TableRow>
                            <TableCell pad={{ right: 'none' }} >
                                <Text weight='bold' size="small">{site.localizedStrings['ExternalPages.TrainingCompany_Label']}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{offering.company}</Text>
                            </TableCell>
                        </TableRow>}
                        {offering.vendorCourseId && <TableRow>
                            <TableCell pad={{ right: 'none' }} >
                                <Text weight='bold' size="small">{site.localizedStrings['ExternalPages.VendorCourseID_Label']}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{offering.vendorCourseId}</Text>
                            </TableCell>
                        </TableRow>}
                        {courseDates && <TableRow>
                            <TableCell pad={{ right: 'none' }} >
                                <Text weight='bold' size="small">{site.localizedStrings['ExternalPages.CourseDates_Label']}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{courseDates}</Text>
                            </TableCell>
                        </TableRow>}
                        <TableRow>
                            <TableCell pad={{ right: 'none' }} >
                                <Text weight='bold' size="small">{site.localizedStrings['ExternalPages.Duration_Label']}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{offering.duration}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell pad={{ right: 'none' }} >
                                <Text weight='bold' size="small">{site.localizedStrings['ExternalPages.CourseType_Label']}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{offering.courseType}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell pad={{ right: 'none' }} >
                                <Text weight='bold' size="small">{site.localizedStrings['ExternalPages.Address_Label']}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{offering.location}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell pad={{ right: 'none' }} >
                                <Text weight='bold' size="small">{site.localizedStrings['ExternalPages.Guaranteed_To_Run']}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{offering.guaranteedToRun}</Text>
                            </TableCell>
                        </TableRow>
                        {offering.isMaseOffering && <TableRow>
                            <TableCell colSpan={2} pad={{ right: 'none' }} >
                                <Text weight='bold'>{site.localizedStrings['ExternalPages.TainingCalender_MaseOffiing_Statement']}</Text>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
                <Button alignSelf="start" label="Register" className="hpe-button" href={offering.registerUrl}></Button>
            </CardBody>
        </Card>;
    }

    setShowOfferings = (show: boolean) => {
        this.setState({ showOfferings: show });
    }

    showOfferings = (evt: any, courseInfo: CourseInfo) => {
        let content = courseInfo.trainingModes.length > 1
            ? <Tabs justify="start">
                {courseInfo.trainingModes.map((mode, index) => {
                    let modeOfferings = courseInfo.offerings.filter((offering) => offering.trainingMode === mode);

                    return <Tab title={`${mode} - ${modeOfferings.length}`} key={`mode-tab-${index}`} >
                        <Box height="medium" overflow="auto" gap="xsmall">
                            {modeOfferings.map((offering, ndx) => this.getOfferingTable(offering))}
                        </Box>
                    </Tab>
                })}
            </Tabs>
            : <Box height="medium" overflow="auto" gap="xsmall">
                {courseInfo.offerings.map((offering) => this.getOfferingTable(offering))}
            </Box>;
        let view = <Box pad="small" >
            <Heading level='4' fill>{courseInfo.title}</Heading>
            {courseInfo.dataCardUrl && <Button alignSelf="start" label="View Datacard" margin={{ bottom: "small" }} className="hpe-button-secondary"
                href={courseInfo.dataCardUrl}></Button>}
            {content}
        </Box>;

        this.setState({ showOfferings: true, offeringView: view });
    }

    applyFilters = () => {
        this.retrieveCourses();
    }

    resetFilters = () => {
        this.props.setKeywords('');
        this.props.setStartDate('');
        this.props.setEndDate('');
        this.props.setSelected('calendarViews-0-false');
        this.props.setSelected('regions-0-true');
        this.props.setSelected('courseTypes-0-true');
        this.props.setSelected('trainingModes-0-true');
        this.props.setSelected('languages-0-true');
        this.props.setSelected('jobRoles-0-true');
        this.props.setSelected('technologies-0-true');
        this.props.setSelected('trainingCompanies-0-true');
        this.retrieveCourses();
    }

    onKeywordChange = (value: string) => {
        this.props.setKeywords(value);
    }

    renderFilters = (size: string) => {
        let site = this.props.site;
        let options = this.props.calendar.options;
        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : null
            : null;
        let rowSizes = ['xlarge', 'large', 'medium']

        return <Box fill="horizontal">
            <Box direction={rowSizes.indexOf(size) === -1 ? "column" : "row-responsive"} fill="horizontal" gap="medium" >
                <Box gap="small" justify="center">
                    <Box direction="row-responsive" fill="horizontal" gap="medium" >
                        <Box width={{ min: "medium" }} >
                            <TextInput size='xsmall' placeholder={`${site.localizedStrings['ExternalPages.TrainingCalendar_Search_Placeolder']} ...`}
                                value={this.props.calendar.keywords} icon={<Search />}
                                onChange={event => this.onKeywordChange(event.target.value)} />
                        </Box>
                        <Box direction="row-responsive" gap="small" justify="center" >
                            <Box justify="center" >
                                <Text weight="bold">{`${site.localizedStrings['ExternalPages.Date_Range_Label']}:`}</Text>
                            </Box>
                            <Box direction="row-responsive" gap="small" justify="center">
                                <Box width={{ max: "small" }} >
                                    <DateInput format="mm/dd/yyyy" value={this.props.calendar.startDate} onChange={this.onStartDateChanged} />
                                </Box>
                                <Text weight="bold">&nbsp;-&nbsp;</Text>
                                <Box width={{ max: "small" }} >
                                    <DateInput format="mm/dd/yyyy" value={this.props.calendar.endDate} onChange={this.onEndDateChanged} />
                                </Box>
                            </Box>
                        </Box>
                        <DropButton className="hpe-button-box" label={site.localizedStrings['ExternalPages.GroupBy_Label']}
                            dropAlign={{ top: 'bottom', left: 'left' }}
                            dropContent={
                                <Box height={{ max: "medium" }}>
                                    {options.entityTypes.map((view, index) => <CheckBox key={`entityTypes-${index}`} id={`entityTypes-${index}`}
                                        checked={view.selected} label={view.text} onChange={this.onCheckedChange} />)}
                                </Box>
                            }
                        />
                    </Box>
                    <Box direction={rowSizes.indexOf(size) === -1 ? "column" : "row-responsive"} fill="horizontal" gap="small" justify="center">
                        <Text weight="bold">{site.localizedStrings['ExternalPages.Narrow_by_Label']}:</Text>
                        <DropButton className="hpe-button-box" label={site.localizedStrings['ExternalPages.CalendarView_Label']}
                            dropAlign={{ top: 'bottom', left: 'left' }}
                            dropContent={
                                <Box height={{ max: "medium" }} width={{ min: "medium" }}>
                                    {options.calendarViews.map((view, index) => <CheckBox key={`calendarViews-${index}`} id={`calendarViews-${index}`}
                                        checked={view.selected} label={view.text} onChange={this.onCheckedChange} />)}
                                </Box>
                            }
                        />
                        <DropButton className="hpe-button-box" label={site.localizedStrings['ExternalPages.Regions_Label']}
                            dropAlign={{ top: 'bottom', left: 'left' }}
                            dropContent={
                                <Box height={{ max: "medium" }}>
                                    {options.regions.map((view, index) => <CheckBox key={`regions-${index}`} id={`regions-${index}`}
                                        checked={view.selected} label={view.text} onChange={this.onCheckedChange} />)}
                                </Box>
                            }
                        />
                        <DropButton className="hpe-button-box" label={site.localizedStrings['ExternalPages.CourseType_Label']}
                            dropAlign={{ top: 'bottom', left: 'left' }}
                            dropContent={
                                <Box height={{ max: "medium" }}>
                                    {options.courseTypes.map((view, index) => <CheckBox key={`courseTypes-${index}`} id={`courseTypes-${index}`}
                                        checked={view.selected} label={view.text} onChange={this.onCheckedChange} />)}
                                </Box>
                            }
                        />
                        <DropButton className="hpe-button-box" label={site.localizedStrings['ExternalPages.DeliveryFormats_Label']}
                            dropAlign={{ top: 'bottom', left: 'left' }}
                            dropContent={
                                <Box height={{ max: "medium" }}>
                                    {options.trainingModes.map((view, index) => <CheckBox key={`trainingModes-${index}`} id={`trainingModes-${index}`}
                                        checked={view.selected} label={view.text} onChange={this.onCheckedChange} />)}
                                </Box>
                            }
                        />
                        <DropButton className="hpe-button-box" label={site.localizedStrings['ExternalPages.DeliveryLanguage_Label']}
                            dropAlign={{ top: 'bottom', left: 'left' }}
                            dropContent={
                                <Box height={{ max: "medium" }} width={{ min: urlLanguage === null ? "193px" : "205px" }}>
                                    {options.languages.map((view, index) => <CheckBox key={`languages-${index}`} id={`languages-${index}`}
                                        checked={view.selected} label={view.text} onChange={this.onCheckedChange} />)}
                                </Box>
                            }
                        />
                        <DropButton className="hpe-button-box" label={site.localizedStrings['ExternalPages.JobRole_Label']}
                            dropAlign={{ top: 'bottom', left: 'left' }}
                            dropContent={
                                <Box height={{ max: "medium" }}>
                                    {options.jobRoles.map((view, index) => <CheckBox key={`jobRoles-${index}`} id={`jobRoles-${index}`}
                                        checked={view.selected} label={view.text} onChange={this.onCheckedChange} />)}
                                </Box>
                            }
                        />
                        <DropButton className="hpe-button-box" label={site.localizedStrings['ExternalPages.Technologies_Label']}
                            dropAlign={{ top: 'bottom', left: 'left' }}
                            dropContent={
                                <Box height={{ max: "medium" }} width={{ min: "230px" }}>
                                    {options.technologies.map((view, index) => <CheckBox key={`technologies-${index}`} id={`technologies-${index}`}
                                        checked={view.selected} label={view.text} onChange={this.onCheckedChange} />)}
                                </Box>
                            }
                        />
                        <DropButton className="hpe-button-box" label={site.localizedStrings['ExternalPages.TrainingCompany_Label']}
                            dropAlign={{ top: 'bottom', left: 'left' }}
                            dropContent={
                                <Box height={{ max: "medium" }}>
                                    {options.trainingCompanies.map((view, index) => <CheckBox key={`trainingCompanies-${index}`} id={`trainingCompanies-${index}`}
                                        checked={view.selected}
                                        label={view.text}
                                        onChange={this.onCheckedChange}
                                    />)}
                                </Box>
                            }
                        />
                    </Box>
                </Box>
                <Box>
                    <Box fill />
                    <Button className="hpe-button" label={site.localizedStrings['ExternalPages.Apply_Filters_Label']} onClick={this.applyFilters} />
                </Box>
                <Box>
                    <Box fill />
                    <Button className="hpe-button-secondary" label={site.localizedStrings['ExternalPages.Reset_Filters_Label']} onClick={this.resetFilters} />
                </Box>
            </Box>
        </Box>;
    }

    renderCourses = () => {
        let site = this.props.site;
        let content: any = <Text>{site.localizedStrings['ExternalPages.No_Matching_Offerings_Statement']}</Text>;
        let options = this.props.calendar.options;
        let courses = this.props.calendar.courses.list;
        let selectedTypes = options.entityTypes.filter((type) => type.selected);
        let guaranteedToRun = options.calendarViews.some((view) => view.selected && view.value === 'Guaranteed to run only');

        if ((selectedTypes.length === 0) || !courses.some((course) => course.entityName)) {
            content = courses.length === 0
                ? <Text weight="bold" size="large" textAlign="center">{site.localizedStrings['ExternalPages.No_Matching_Offerings_Statement']}</Text>
                : <Grid gap={{ row: "small", column: "small" }} rows="medium" columns="250px" >
                    {
                        _.map(courses, (courseInfo) => {
                            let courseNumber = courseInfo.number;

                            return <CourseCard data={courseInfo} key={`course-${courseNumber}`} id={`course-${courseNumber}`}
                                hoverIndicator onClick={(e: any) => { this.showOfferings(e, courseInfo) }} />
                        })
                    }
                </Grid>;
        }
        else if (selectedTypes.length === 1) {
            let groupings = _.groupBy(courses, (course) => { return [course.entityName] });

            content = <Accordion multiple>
                {
                    _.map(groupings, (items, key) => {
                        return <AccordionPanel label={key}>
                            <Grid gap={{ row: "small", column: "small" }} rows="medium" columns="250px" >
                                {
                                    _.map(items, (courseInfo) => {
                                        let courseNumber = courseInfo.number;

                                        return <CourseCard data={courseInfo} key={`course-${courseNumber}`} id={`course-${courseNumber}`}
                                            hoverIndicator onClick={(e: any) => { this.showOfferings(e, courseInfo) }} />
                                    })
                                }
                            </Grid>
                        </AccordionPanel>
                    })}
            </Accordion>
        }
        else {
            let typeGroupings = _.groupBy(courses, (course) => { return [course.entityType] });

            content = <Accordion>
                {
                    _.map(typeGroupings, (typeItems, typeKey) => {
                        let groupings = _.groupBy(typeItems, (course) => { return [course.entityName] });

                        return <AccordionPanel label={typeKey}>
                            <Box pad={{ left: "small" }}>
                                <Accordion multiple>
                                    {
                                        _.map(groupings, (courseItems, key) => {
                                            return <AccordionPanel label={key}>
                                                <Grid gap={{ row: "small", column: "small" }} rows="medium" columns="250px" >
                                                    {
                                                        _.map(courseItems, (courseInfo) => {
                                                            let courseNumber = courseInfo.number;

                                                            return <CourseCard data={courseInfo} key={`course-${courseNumber}`} id={`course-${courseNumber}`}
                                                                hoverIndicator onClick={(e: any) => { this.showOfferings(e, courseInfo) }} />;
                                                        })
                                                    }
                                                </Grid>
                                            </AccordionPanel>;
                                        })}
                                </Accordion>
                            </Box>
                        </AccordionPanel>
                    })
                }
            </Accordion>
        }

        return <Box pad={{ top: 'small' }} gap="small">
            {this.state.showOfferings && <Layer onEsc={() => this.setShowOfferings(false)} onClickOutside={() => this.setShowOfferings(false)}>
                <Box height={{ max: "xlarge" }}>
                    <Box fill="horizontal" direction="row">
                        <Box direction="row-responsive" fill></Box>
                        <Button alignSelf="end" icon={<Close size="small" />} onClick={() => { this.setState({ showOfferings: false }) }}></Button>
                    </Box>
                    {this.state.offeringView}
                </Box>
            </Layer>}
            {guaranteedToRun && <Box>
                <Text>{site.localizedStrings['ExternalPages.Guaranteed_To_Run_Statement']}</Text>
            </Box>}
            {content}
        </Box>
    }

    gotoPage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        let totalPages = this.props.calendar.courses.totalPages ?? 1;
        let currentPage = this.props.calendar.currentPage;
        let nextPage = currentPage;
        let selectedPage = event.currentTarget.getAttribute("page") ?? undefined;

        switch (selectedPage) {
            case "first": {
                nextPage = 1;
                break;
            }
            case "next": {
                nextPage = Math.min(totalPages, currentPage + 1);
                break;
            }
            case "previous": {
                nextPage = Math.max(1, currentPage - 1);
                break;
            }
            case "last": {
                nextPage = totalPages - 1;
                break;
            }
            default: {
                if (selectedPage) {
                    nextPage = parseInt(selectedPage)
                }
                break;
            }
        }
        if (this.props.calendar.currentPage !== nextPage) {
            this.props.setCurrentPage(nextPage);
            this.retrieveCourses();
        }
    }

    renderPagination = (size: string) => {
        let totalPages = this.props.calendar.courses.totalPages ?? 1;
        let currentPage = this.props.calendar.currentPage;
        let pages = [];
        let startPage = currentPage === totalPages ? Math.max(totalPages - 4, 1) : currentPage;
        let pageSize = this.props.calendar.pageSize;
        let courseStart = ((this.props.calendar.currentPage - 1) * pageSize) + 1;
        let courseCount = this.props.calendar.courses.totalCourses ?? 0;
        let courseEnd = courseCount < pageSize
            ? (courseStart + courseCount) - 1
            : (courseStart + pageSize) - 1;
        let pageMax = Math.min(5, totalPages);

        for (var index = 0; index < pageMax; index++) {
            let page = startPage + index;

            pages.push(
                <PaginationItem key={`page-${page}`} active={currentPage === page}>
                    <PaginationLink page={page} onClick={(event) => { this.gotoPage(event) }}>
                        {page}
                    </PaginationLink>
                </PaginationItem>
            );
        }

        return totalPages > 1
            ? <Box fill="horizontal" direction="row" justify='between' align="baseline" >
                <Box direction="row" align="baseline">
                    <Pagination size="sm" aria-label="Page navigation" style={{ margin: 0 }}>
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink first page="first" onClick={(event) => { this.gotoPage(event) }} />
                        </PaginationItem>
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink previous page="previous" onClick={(event) => { this.gotoPage(event) }} />
                        </PaginationItem>
                        {pages}
                        <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink next page="next" onClick={(event) => { this.gotoPage(event) }} />
                        </PaginationItem>
                        <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink last page="last" onClick={(event) => { this.gotoPage(event) }} />
                        </PaginationItem>
                    </Pagination>
                </Box>
                <Box alignSelf="end">
                    <Text size="small" color="#0D5265">{courseStart} - {courseEnd} of {courseCount}</Text>
                </Box>
            </Box>
            : null;
    }

    retrieveCourses = () => {
        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : null
            : null;

        this.props.history.replace(this.props.location.pathname, this.props.calendar);
        setTimeout(() => {
            this.props.retrieveCourses(urlLanguage);
        }, 500);
    }

    onProfileChange = (profile: any) => {
        let excludePartners = profile.value.value === 'customer';

        this.setState({ profile: profile.value });
        this.props.setExcludePartnersRestricted(excludePartners);
        setTimeout(() => {
            this.applyFilters();
        }), 500;
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Training_Label'], value: "" },
            { key: site.menuStrings['Datacard.Training_Calendar_Label'], value: `${localUrl}/trainingcalendar` }
        ];
        let title = site.menuStrings['Datacard.Training_Calendar_Label'];
        let customer = site.localizedStrings['ExternalPages.Customer_Label'];
        let partner = site.localizedStrings['ExternalPages.Hpe_Partner_Label'];
        let profiles = [{ text: partner, value: "partner" }, { text: `${customer}`, value: "customer" }];

        return <ResponsiveContext.Consumer>
            {size => (
                <BasePage breadcrumbs={breadcrumbs} title={title} pageName="TrainingCalendar" {...this.props}
                    metadataKeywords={site.localizedStrings['Metadata.TrainingCalendarKeywords']}
                    metadataDescription={site.localizedStrings['Metadata.TrainingCalendarDescription']} >
                    {this.props.calendar && site.stringsLoaded && <Box gap="medium">
                        <Box fill="horizontal">
                            <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                        </Box>
                        <Paragraph fill>
                            {parse(formatString(site.localizedStrings['ExternalPages.TrainingCalendar_Info_Statement'],
                                [`${localUrl}/certifications`, `${localUrl}/exams`]))}
                        </Paragraph>
                        <Box direction="row-responsive" align="center" gap="small" fill>
                            <Text weight="bold">Choose your Profile</Text>
                            <Box width="medium">
                                <Select options={profiles} value={this.state.profile} onChange={this.onProfileChange} valueKey="value" labelKey="text" />
                            </Box>
                        </Box>
                        <Box gap="small" pad="small" border={{ side: "bottom", color: 'brand', size: 'small' }}>
                            {this.renderFilters(size)}
                        </Box>
                        <Box gap="xsmall" pad={{ bottom: "small" }}>
                            <Box direction="row-responsive" fill="horizontal">
                                {this.renderPagination(size)}
                            </Box>
                            {this.renderCourses()}
                        </Box>
                    </Box>}
                </BasePage>
            )}
        </ResponsiveContext.Consumer>;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Calendar as any));
