import React from 'react';
import { connect } from 'react-redux';
import {
    Box, Text, Image, Accordion, AccordionPanel, Anchor, Paragraph, ResponsiveContext,
    Table, TableBody, TableRow, TableCell, Layer, Button, CheckBox, TableHeader
} from 'grommet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store/';
import { retrieveExam, examSlice } from './../../store/Exam';
import parse from 'html-react-parser';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import { Exam as ExamModel } from '../../models/Exam';
import { formatString, HPEWindowInterface } from '../../Utilities'
import { KeyValuePair } from '../../models/Common';
import BasePage from '../../components/BasePage';
import HtmlComponent from '../../components/HtmlComponent';

declare let window: HPEWindowInterface;

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    retrieveExam,
    ...examSlice.actions,
    ...siteSlice.actions
}

interface ExamState {
    activePanel: number;
    showWarning: boolean;
    hasAgreed: boolean;
}

type ExamProps =
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Exam extends React.PureComponent<ExamProps, ExamState> {
    constructor(props: ExamProps) {
        super(props);
        this.state = {
            activePanel: 0,
            showWarning: false,
            hasAgreed: false
        };
    }
    public componentDidMount() {
        let currentLang = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : null
            : null;
        let keys = ['Datacard.Digital_Badge_Label', 'Datacard.Digital_Badge_Statement', 'Datacard.Exam_ID_Label', 'Datacard.Questions_Label',
            'Datacard.Exam_NotAvailable', 'Datacard.Exam_Type_Label', 'Datacard.Exam_Duration_Label', 'Datacard.Exam_Length_Label',
            'Datacard.Exxam_Passing_Score_Label', 'Datacard.Print_This_Page', 'ExternalPages.Certification_And_Learning_Label',
            'Datacard.Delivery_Languages_Label', 'Datacard.Related_Certifications_Label', 'Datacard.Exam_Supporting_Resources_Label',
            'Datacard.Exam_AdditionalMaterials_Label', 'Datacard.Exam_Description_Label', 'Datacard.Ideal_Candidate_Label',
            'Datacard.Exam_Contents_Label', 'Datacard.Exam_NumOfQuestions_Statement', 'Datacard.Exam_QuestionType_Label', 'Datacard.Exam_Advice_Label',
            'Datacard.Objectives_Label', 'Datacard.Section_Objectives_Label', 'Datacard.Section_CutScore_Label', 'Datacard.Section_NumOfQuestions_Label',
            'Datacard.Exam_Sample_Questions_Label', 'Datacard.Exam_SampleQuestion_Print_Statement', 'Datacard.Upon_Completion_Label',
            'Datacard.Section_Exam_Percentage', 'Datacard.Exam_PrintDownload_Statement', 'Datacard.Exam_Register_Label', 'Datacard.Summary_Label',
            'Datacard.Details_Label', 'Datacard.Exam_Policies_Statement', 'ExternalPages.Exam_Policies_Label', 'ExternalPages.Security_Questions_Label',
            'ExternalPages.Yes_Label', 'ExternalPages.No_Label', 'ExternalPages.PerformanceBased_Exam_Statement', 'ExternalPages.Cancel_Label',
            'ExternalPages.Submit_Label', 'ExternalPages.Exam_PurchaseVaoucher_Label'];

        this.props.retrieveLocalizedStrings(keys, currentLang);
        this.props.retrieveExam(this.props.match.params.id, currentLang);
    }

    public componentDidUpdate(prevProps: ExamProps) {
        let site = this.props.site;
        let currentLang = site.currentLanguage;
        let previousLang = prevProps.site.currentLanguage;

        if (currentLang.value !== previousLang.value && !site.loading) {
            let urlLanguage = this.props.match
                ? this.props.match.params.lang
                    ? this.props.match.params.lang
                    : null
                : null;

            this.props.retrieveExam(this.props.match.params.id, urlLanguage);
        }
    }

    renderSummary = (exam: ExamModel) => {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let siteUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let summaryData = [];

        summaryData.push(<TableRow>
            <TableCell border="right"><Text weight="bold">{site.localizedStrings['Datacard.Exam_ID_Label']}</Text></TableCell>
            <TableCell>{exam.programId}</TableCell>
        </TableRow>);
        if (exam.examType) {
            summaryData.push(<TableRow>
                <TableCell border="right" ><Text weight="bold">{site.localizedStrings['Datacard.Exam_Type_Label']}</Text></TableCell>
                <TableCell >{exam.examType}</TableCell>
            </TableRow>);
        }
        if (exam.duration) {
            summaryData.push(<TableRow>
                <TableCell border="right" ><Text weight="bold">{site.localizedStrings['Datacard.Exam_Duration_Label']}</Text></TableCell>
                <TableCell ><Box>{parse(exam.duration)}</Box></TableCell>
            </TableRow>);
        }
        if (exam.questions > 0 && !exam.isEdge) {
            summaryData.push(<TableRow>
                <TableCell border="right" ><Text weight="bold">{site.localizedStrings['Datacard.Exam_Length_Label']}</Text></TableCell>
                <TableCell ><Box>{formatString(site.localizedStrings['Datacard.Questions_Label'], [`${exam.questions}`])}</Box></TableCell>
            </TableRow>);
        }
        if (exam.passingScore > 0 && !exam.isEdge) {
            summaryData.push(<TableRow>
                <TableCell border="right" ><Text weight="bold">{site.localizedStrings['Datacard.Exxam_Passing_Score_Label']}</Text></TableCell>
                <TableCell ><Box>{`${exam.passingScore}%`}</Box></TableCell>
            </TableRow>);
        }
        summaryData.push(<TableRow>
            <TableCell border="right" ><Text weight="bold">{site.localizedStrings['Datacard.Delivery_Languages_Label']}</Text></TableCell>
            <TableCell><Box>{exam.deliveryLanguages}</Box></TableCell>
        </TableRow>);
        if (exam.relatedCertifications.length !== 0) {
            let releatedCerts = exam.relatedCertifications.map((related, index) => {
                if (related.isActive) {
                    let url: string = `${localUrl}/datacard/${related.entityType}/${related.modifiedProgramId}`;

                    if (related.entityType.indexOf('Other') !== -1) {
                        url = formatString(`${site.localizedStrings['General.ExternalSabaUrl']}`, [related.programId]);
                    }
                    return <Anchor href={url} label={related.name} key={`rc-${index}`} size="small"></Anchor>
                }
                return <Text>{parse(related.name)}</Text>
            });
            summaryData.push(<TableRow>
                <TableCell border="right" ><Text weight="bold">{site.localizedStrings['Datacard.Related_Certifications_Label']}</Text></TableCell>
                <TableCell ><Box>{releatedCerts}</Box></TableCell>
            </TableRow>);
        }
        if (exam.options.length !== 0) {
            let options = exam.options.map((option, index) => {
                let items = option.items.map((item, ndx) => {
                    if (item.isActive) {
                        let url: string = `${localUrl}/datacard/${item.entityType}/${item.modifiedProgramId}`;

                        if (item.entityType.indexOf('Other') !== -1) {
                            url = formatString(`${site.localizedStrings['General.ExternalSabaUrl']}`, [item.programId]);
                        }
                        return <Anchor href={url} label={item.name} key={`rc-${index}`} size="small"></Anchor>
                    }
                    return <Text>{parse(item.name)}</Text>
                });
                return <Box key={`opt-box-${index}`}>
                    {
                        exam.options.length > 1 && <Box>
                            <Text>{option.name}</Text>
                        </Box>
                    }
                    {items}
                </Box>
            });
            summaryData.push(<TableRow>
                <TableCell border="right"><Text weight="bold">{site.localizedStrings['Datacard.Exam_Supporting_Resources_Label']}</Text></TableCell>
                <TableCell><Box>{options}</Box></TableCell>
            </TableRow>);
        }
        if (exam.additionalStudyMaterial.length !== 0) {
            let material = exam.additionalStudyMaterial.map((am, index) =>
                am.value ?
                    <Anchor href={am.value} key={`am-${index}`}>{am.key}</Anchor>
                    : <Text weight="bold" key={`am-${index}`}>{am.key}</Text>
            );
            summaryData.push(<TableRow>
                <TableCell border="right"><Text weight="bold">{site.localizedStrings['Datacard.Exam_AdditionalMaterials_Label']}</Text></TableCell>
                <TableCell><Box>{material}</Box></TableCell>
            </TableRow>);
        }
        return <Table>
            <TableBody>
                {summaryData}
            </TableBody>
        </Table>;
    }

    onPanelChange = (activePanels: number[]) => {
        if (activePanels.length !== 0) {
            this.setState({ activePanel: activePanels[0] });
        }
        else {
            this.setState({ activePanel: -1 });
        }
    }

    showDigitalBaage = () => {
        let badgeElement = document.getElementById('badge-statement');

        this.setState({ activePanel: 3 });
        badgeElement?.scrollIntoView();
    }

    onRegister = (exam: ExamModel) => {
        if (exam.isPerformanceBased && exam.securityQuestions) {
            this.setState({ showWarning: true });
        }
        else {
            let target = exam.registrationUrl.toLowerCase().indexOf(exam.publicSiteUrl.toLowerCase()) === -1
                ? "_blank"
                : "_self";

            try {
                window.dispatchEvent(new CustomEvent("ANALYTICS.LINK", { detail: { link_name: `Exam|DataCard|${exam.programId}` } }));
            } catch (error) {
                console.log(error);
            }
            window.open(exam.registrationUrl, target);
        }
    }

    onPurchaseVaoucher = (exam: ExamModel) => {
        let target = exam.registrationUrl.toLowerCase().indexOf(exam.publicSiteUrl.toLowerCase()) === -1
            ? "_blank"
            : "_self";

        try {
            window.dispatchEvent(new CustomEvent("ANALYTICS.LINK", { detail: { link_name: `Exam|DataCard|${exam.programId}` } }));
        } catch (error) {
            console.log(error);
        }
        window.open(exam.registrationUrl, target);
    }

    onRegisterPerformanceBased = () => {
        let exam = this.props.exam.data as ExamModel;
        let registrationUrl = "https://auth.hpe.com/home/hpe-greenlake-hub_211486performancebasedexamsolutionscdi_1/0oaj8xb4j794dAP4h357/alnj8xmk2uIFLvca3357";

        try {
            window.dispatchEvent(new CustomEvent("ANALYTICS.LINK", { detail: { link_name: `Exam|DataCard|${exam.programId}` } }));
        } catch (error) {
            console.log(error);
        }
        this.setState({ hasAgreed: false, showWarning: false });
        window.open(registrationUrl, "_blank");
    }

    renderExam = (exam: ExamModel) => {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let siteUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let url = `${localUrl}/datasheet/exam/${exam.programId}?version=${exam.version}`;
        let showPercentage = exam.objectives.filter((obj) => obj.percentOf !== '' || obj.percentOf !== undefined);
        let objectives = <Table>
            <TableHeader>
                <TableRow>
                    {(showPercentage != null) &&
                        <TableCell border="right" background="#263040" align="center"><Text color="white">Percentage of Exam</Text></TableCell>
                    }
                    {exam.isEdge &&
                        <TableCell border="right" background="#263040" align="center"><Text color="white">Passing Score</Text></TableCell>
                    }
                    {exam.isEdge &&
                        <TableCell border="right" background="#263040" align="center"><Text color="white">Number of Questions</Text></TableCell>
                    }
                    <TableCell border="right" background="#263040" align="center"><Text color="white">Sections/Objectives</Text></TableCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {exam.objectives.map((obj, index) => {
                    let cells = [];

                    if (showPercentage != null) {
                        cells.push(<TableCell border="right" key={"obj-cell-1"} align="center"><Text>{`${obj.percentOf}%`}</Text></TableCell>);
                    }
                    if (exam.isEdge) {
                        cells.push(<TableCell border="right" key={"obj-cell-2"} align="center"><Text>{`${obj.cutScore}`}</Text></TableCell>);
                        cells.push(<TableCell border="right" key={"obj-cell-3"} align="center"><Text>{`${obj.numberOfQuestions}`}</Text></TableCell>);
                    }
                    cells.push(<TableCell key={"obj-cell-4"}>{parse(obj.sectionObjectives)}</TableCell>);
                    return <TableRow key={`obj-${index}`}>
                        {cells}
                    </TableRow>
                })}
            </TableBody>
        </Table>;

        return <ResponsiveContext.Consumer>
            {size => (
                <Box fill>
                    {this.state.showWarning && <Layer>
                        <Box width={{ max: "large" }} pad="small" gap="small">
                            <Box height="medium" overflow="auto" pad={{ top: "small", left: "small", right: "small" }}>
                                <Paragraph fill>{parse(exam.securityQuestions)}</Paragraph>
                            </Box>
                            <CheckBox label={parse(site.localizedStrings['ExternalPages.PerformanceBased_Exam_Statement'])}
                                onChange={(event) => { this.setState({ hasAgreed: event.target.checked }) }} />
                            <Box direction="row-responsive" fill="horizontal" justify="center" gap="small">
                                <Button alignSelf="end" primary
                                    label={<Text color="white">{parse(site.localizedStrings['ExternalPages.Submit_Label'])}</Text>}
                                    onClick={() => { this.onRegisterPerformanceBased() }} disabled={!this.state.hasAgreed} />
                                <Button alignSelf="end" secondary label={site.localizedStrings['ExternalPages.Cancel_Label']}
                                    onClick={() => { this.setState({ hasAgreed: false, showWarning: false }) }} />
                            </Box>
                        </Box>
                    </Layer>
                    }
                    {exam.hasDigitalBadge &&
                        <Box fill="horizontal" >
                            <Anchor onClick={() => { this.showDigitalBaage(); }} alignSelf="center">
                                <Box height={size === "xsmall" ? "small" : "288px"} width={size === "xsmall" ? "small" : "288px"} pad={{ bottom: "small" }}>
                                    <Image fit="contain" src={exam.digitalBadge} />
                                </Box>
                            </Anchor>
                        </Box>
                    }
                    <Box pad={{ bottom: "small" }} gap="small">
                        <Paragraph fill>{parse(exam.description)}</Paragraph>
                        <Box fill="horizontal">
                            <Paragraph fill>{parse(exam.registrationInstructions)}</Paragraph>
                            <Box direction="row-responsive" fill="horizontal" gap="medium">
                                {exam.isPerformanceBased && exam.isAruba &&
                                    <Anchor onClick={() => { this.onPurchaseVaoucher(exam) }}
                                        style={{ fontWeight: "bold" }} label={site.localizedStrings['ExternalPages.Exam_PurchaseVaoucher_Label']} />
                                }
                                <Anchor onClick={() => { this.onRegister(exam) }} style={{ fontWeight: "bold" }}
                                    label={site.localizedStrings['Datacard.Exam_Register_Label']} />
                            </Box>
                        </Box>
                        {exam.testingDisclaimer &&
                            <Box fill="horizontal">
                                <Paragraph fill>{parse(exam.testingDisclaimer)}</Paragraph>
                            </Box>}
                    </Box>
                    <Accordion activeIndex={this.state.activePanel} onActive={activeIndexes => { this.onPanelChange(activeIndexes); }}>
                        <AccordionPanel label={site.localizedStrings['Datacard.Summary_Label']}>
                            <Box pad={{ top: "small" }} width={{ max: "large" }}>
                                {this.renderSummary(exam)}
                            </Box>
                        </AccordionPanel>
                        <AccordionPanel label={site.localizedStrings['Datacard.Details_Label']}>
                            <Box gap="small" pad={{ left: "medium" }}>
                                <Box pad={{ top: "small" }}>
                                    <Text weight='bold' size="small">{site.localizedStrings['Datacard.Ideal_Candidate_Label']}</Text>
                                    <Text>{parse(exam.whoShouldTake)}</Text>
                                </Box>
                                {exam.questions > 0 && <Box>
                                    <Text weight='bold' size="small">{site.localizedStrings['Datacard.Exam_Contents_Label']}</Text>
                                    <Paragraph fill>
                                        {parse(formatString(site.localizedStrings['Datacard.Exam_NumOfQuestions_Statement'], [`${exam.questions}`]))}
                                    </Paragraph>
                                </Box>}
                                {exam.questionTypes.length > 0 && <Box>
                                    <Text weight='bold' size="small">{site.localizedStrings['Datacard.Exam_QuestionType_Label']}</Text>
                                    <Box>{exam.questionTypes.map((qt, index) => <Text key={`qt-${index}`}>{qt}</Text>)}</Box>
                                </Box>}
                                {exam.tipsForTaking && <Box>
                                    <Text weight='bold' size="small">{site.localizedStrings['Datacard.Exam_Advice_Label']}</Text>
                                    <HtmlComponent fill="horizontal" html={exam.tipsForTaking} />
                                </Box>}
                                {exam.secondParagraph && <Box>
                                    <HtmlComponent fill="horizontal" html={exam.secondParagraph} />
                                </Box>}
                                {exam.thirdParagraph && <Box>
                                    <HtmlComponent fill="horizontal" html={exam.thirdParagraph} />
                                </Box>}
                                <Box>
                                    <Text weight='bold' size="small">{site.localizedStrings['ExternalPages.Exam_Policies_Label']}</Text>
                                    <Box fill="horizontal">
                                        {parse("<span>" + formatString(site.localizedStrings['Datacard.Exam_Policies_Statement'],
                                            [`${localUrl}/certification-exams`]) + "</span>")}
                                    </Box>
                                </Box>
                            </Box>
                        </AccordionPanel>
                        <AccordionPanel label={site.localizedStrings['Datacard.Objectives_Label']}>
                            <Box pad={{ left: "medium" }} gap="small">
                                <HtmlComponent html={exam.objectiveStatement} />
                                {objectives}
                            </Box>
                        </AccordionPanel>
                        {exam.hasSampleQuestions && <AccordionPanel label={site.localizedStrings['Datacard.Exam_Sample_Questions_Label']}>
                            <Box pad={{ top: "small", left: "medium" }} gap="small">
                                <HtmlComponent fill="horizontal" html={exam.sampleQuestionDescription} />
                                <Anchor href={url} label={site.localizedStrings['Datacard.Exam_PrintDownload_Statement']} />
                            </Box>
                        </AccordionPanel>}
                        {exam.hasDigitalBadge && <AccordionPanel label={site.localizedStrings['Datacard.Upon_Completion_Label']}>
                            <Box pad={{ top: "small", left: "medium" }} gap="small">
                                <Paragraph fill id="badge-statement">{parse(site.localizedStrings['Datacard.Digital_Badge_Statement'])}</Paragraph>
                            </Box>
                        </AccordionPanel>}
                        {exam.isPerformanceBased && exam.securityQuestions && <AccordionPanel label={site.localizedStrings['ExternalPages.Security_Questions_Label']}>
                            <Paragraph fill>
                                <HtmlComponent html={exam.securityQuestions} createReactComponents />
                            </Paragraph>
                        </AccordionPanel>}
                    </Accordion>
                </Box>
            )}
        </ResponsiveContext.Consumer>;
    }

    public render() {
        let exam = this.props.exam.data;
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let siteUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['ExternalPages.Navigation_Technical'], value: `${localUrl}/technical-certifications` },
            { key: site.menuStrings['Datacard.Exam_ViewAll_Label'], value: `${localUrl}/exams` },
            { key: exam?.name, value: `datacard/exam/${exam?.programId}` }
        ];
        let printUrl = `${localUrl}/datasheet/exam/${exam?.programId}?version=${exam?.version}`;
        let title = exam?.name ?? '';

        return <ResponsiveContext.Consumer>
            {size => (
                <BasePage breadcrumbs={breadcrumbs} title={title} pageName={`datacard_${exam?.programId}`} print={printUrl} {...this.props}
                    metadataKeywords={exam?.metadataKeywords} metadataDescription={exam?.metadataDescription} >
                    {exam && <Box fill>
                        {this.renderExam(exam)}
                    </Box>
                    }
                    {!this.props.site.loading && !exam && <Box fill align="center" justify="center" height={{ min: "medium" }}>
                        {parse(formatString(site.localizedStrings['Datacard.Exam_NotAvailable'], [siteUrl]))}
                    </Box>
                    }
                </BasePage>
            )}
        </ResponsiveContext.Consumer>;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Exam as any));
