import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store/';
import { KeyValuePair } from '../models/Common';
import { Box, Paragraph, ResponsiveContext, Image, Heading, Text, Button, Anchor, ThemeContext, Header, Grid, Card, CardBody, Layer } from 'grommet';
import { LinkNext, FormClose, CirclePlay } from 'grommet-icons';
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import { retrieveWhatIsNew } from '../store/Overview';
import BasePage from '../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';
import classNames from 'classnames';
import { HPEWindowInterface } from '../Utilities'
import { push } from 'connected-react-router';

declare let window: HPEWindowInterface;

const mapDispatchToProps = {
    push,
    retrieveLocalizedStrings,
    retrieveWhatIsNew,
    ...siteSlice.actions,
}

interface IOverviewProps {
}

interface OverviewState {
    firstTab?: string;
    activeTab: number;
    activeSlide: number;
    activeSection: number;
    showVideo: boolean;
}

type OverviewProps = IOverviewProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Overview extends React.PureComponent<OverviewProps, OverviewState> {
    constructor(props: OverviewProps) {
        super(props);

        let activeTab = 0;
        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let tab = this.props.match
            ? this.props.match.params.activeTab
                ? this.props.match.params.activeTab
                : null
            : null;
        let keys = ['ExternalPages.Overview_Welcome', 'ExternalPages.Overview_Banner_Title',
            'Metadata.OverviewKeywords,', 'Metadata.OverviewDescription', 'ExternalPages.Overview_Banner_Button1',
            'ExternalPages.Overview_Banner_Staement', 'ExternalPages.Overview_Banner_Button2',
            'ExternalPages.Overview_Section2_Button_Label', 'ExternalPages.Overview_UpskillTraining_Box3_Statement',
            'ExternalPages.Overview_WhatsNew', 'ExternalPages.Overview_GetCertified_Statement',
            'ExternalPages.Overview_GetCertified_Statement_Anchor1', 'ExternalPages.Overview_GetCertified_Statement_Anchor2',
            'ExternalPages.Overview_GetCertified_Statement_Anchor3', 'ExternalPages.Overview_UpskillTraining',
            'ExternalPages.Overview_UpskillTraining_Box3_Link', 'ExternalPages.Overview_UpskillTraining_Box3_Title',
            'ExternalPages.Overview_UpskillTraining_Statement', 'ExternalPages.Overview_MoreCommunities_Label',
	    'ExternalPages.Overview_GetCertified_Statement_Anchor4',	    
            'ExternalPages.Overview_UpskillTraining_Box1_Title', 'ExternalPages.Overview_UpskillTraining_Box2_Title',
            'ExternalPages.Overview_UpskillTraining_Box1_Statement', 'ExternalPages.Overview_UpskillTraining_Box2_Statement',
            'ExternalPages.Overview_MoreLearning_Statement', 'ExternalPages.Overview_MoreLearning_Partners_Label',
            'ExternalPages.Overview_MoreLearning_Customers_Label', 'ExternalPages.Overview_MoreLearning_Employees_Label',
            'ExternalPages.Overview_MoreLearning_Communities_Label', 'ExternalPages.Overview_UpskillTraining_Box2_Link',
            'ExternalPages.Overview_MoreLearning_EnablementPrograms_Label', 'ExternalPages.Overview_MoreLearning_MoreCertifications_Label',
            'ExternalPages.Overview_MoreLearning_Communities_Box1_Title', 'ExternalPages.Overview_MoreLearning_Communities_Box1_Statement',
            'ExternalPages.JoinNow_Label', 'ExternalPages.GoNow_Label', 'ExternalPages.Overview_UpskillTraining_Box1_Link',
            'ExternalPages.Overview_MoreLearning_Communities_Box2_Statement', 'ExternalPages.Overview_MoreLearning_Enablement_Box1_Title',
            'ExternalPages.Overview_MoreLearning_Enablement_Box1_Statement', 'ExternalPages.Overview_MoreLearning_Enablement_Box2_Title',
            'ExternalPages.Overview_MoreLearning_Enablement_Box2_Statement', 'ExternalPages.Overview_MoreLearning_Enablement_Box3_Title',
            'ExternalPages.Overview_MoreLearning_Enablement_Box3_Statement', 'ExternalPages.Overview_MoreLearning_Enablement_Box4_Title',
            'ExternalPages.Overview_MoreLearning_Enablement_Box4_Statement', 'ExternalPages.Overview_MoreLearning_MoreCerts_Box1_Title',
            'ExternalPages.Overview_MoreLearning_MoreCerts_Box1_Statement', 'ExternalPages.Overview_MoreLearning_MoreCerts_Box2_Statement',
            'ExternalPages.Learn_More_Label', 'ExternalPages.Overview_Section2', 'ExternalPages.Overview_Section2_Statement',
            'Datacard.Learning_Center_Label', 'ExternalPages.Overview_Banner_Footnotes', 'ExternalPages.Read_The_Brochure_Label',
            'ExternalPages.Overview_UpskillTraining_Footnote', 'ExternalPages.Back_To_Top_Label'];

        switch (tab) {
            case 'get-certified':
                activeTab = 1;
                break;
            case 'upskill-training':
                activeTab = 2;
                break;
            case 'more-learning':
                activeTab = 3;
                break;
        }
        this.state = {
            firstTab: tab,
            activeTab: activeTab,
            activeSlide: 0,
            activeSection: 0,
            showVideo: false
        };
        this.props.setActiveMenu('overview-menu');
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.retrieveWhatIsNew(langInfo[0]);
        // if (tab) {
        //     setTimeout(() => {
        //         this.scrollIntoView(tab);
        //     }, 200);
        // }
    }

    public componentDidUpdate() {
        if (this.state.firstTab) {
            let tab = this.state.firstTab;

            this.setState({ firstTab: undefined })
            setTimeout(() => {
                this.scrollIntoView(tab);
            }, 1000);
        }
    }

    scrollIntoView = (id: string) => {
        let elem = document.getElementById(id);
        let activeSection = 0;

        switch (id) {
            case 'get-certified':
                {
                    activeSection = 1;
                    break;
                }
            case 'upskill-training':
                {
                    activeSection = 2;
                    break;
                }
            case 'more-learning':
                {
                    activeSection = 3;
                    break;
                }
            default:
                {
                    activeSection = 0;
                    break;
                }
        }
        this.setState({ activeSection: activeSection });
        elem?.scrollIntoView();
    }

    onActivateChild = (activeSlide: number) => {
        let activeSection = 0;

        switch (activeSlide) {
            case 0:
            case 1:
                activeSection = 0;
                break;
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                activeSection = 1;
                break;
            case 7:
            case 8:
                activeSection = 2;
                break;
            case 9:
                activeSection = 3;
                break;
        }
        this.setState({ activeSection: activeSection, activeSlide: activeSlide });
    }

    onNext = () => {
        this.onActivateChild(this.state.activeSlide + 1)
    }

    onPrevious = () => {
        this.onActivateChild(this.state.activeSlide - 1)
    }

    getPercentagePosition = (size: string) => {
        return size === 'medium_xsmall'
            ? '57%'
            : size === 'small'
                ? "35%"
                : size === 'xsmall'
                    ? "37%"
                    : size === 'xxsmall'
                        ? "39%"
                        : "55%";
    }

    onCloseVideo = () => {
        this.setState({ showVideo: false });
    }

    onShowVideo = () => {
        this.setState({ showVideo: true });
    }

    onWhatsNewClicked = (headline: string) => {
        try {
            headline = headline.replace(" ", "_");
            window.dispatchEvent(new CustomEvent("ANALYTICS.LINK", { detail: { link_name: `WhatsNew|${headline}` } }));
        } catch (error) {
            console.log(error);
        }
    }

    public render() {
        let site = this.props.site;
        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: "" }
        ];
        let title = site.localizedStrings['ExternalPages.Overview_Welcome'];
        let borderColors = ['#7630EA', '#C140FF', '#FF8300', '#FEC901'];

        return <ResponsiveContext.Consumer>
            {size => (
                <BasePage breadcrumbs={breadcrumbs} title={title} pageName="Overview" {...this.props}
                    metadataKeywords={site.localizedStrings['Metadata.OverviewKeywords']}
                    metadataDescription={site.localizedStrings['Metadata.OverviewDescription']} >
                    {site.stringsLoaded && <Box gap="medium" pad={{ bottom: "medium" }}>
                        <Box gap="medium" id="overview-banner" pad="medium" >
                            <Box direction="row-responsive" fill gap="large">
                                <Box fill pad={{ left: "medium" }} alignSelf="center" gap="small">
                                    <Heading level={['xxsmall', 'xsmall', 'small'].indexOf(size) == -1 ? '2' : '3'}>
                                        {(site.localizedStrings['ExternalPages.Overview_Banner_Title'] ?? "")}
                                    </Heading>
                                    <Box gap="medium">
                                        <Paragraph size="large" fill>{parse(site.localizedStrings['ExternalPages.Overview_Banner_Staement'])}</Paragraph>
                                        <Box direction="row-responsive" gap="medium">
                                            <Button primary color="brand" href='https://www.hpe.com/psnow/doc/4aa4-2093enw​' target="_blank"
                                                label={<Text color="white">{site.localizedStrings['ExternalPages.Read_The_Brochure_Label']}</Text>} />
                                            <Button primary color="brand" href={`${localUrl}/certifications-advantage`}
                                                label={<Text color="white">{site.localizedStrings['ExternalPages.Overview_Banner_Button2']}</Text>} />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box fill hoverIndicator onClick={() => { this.setState({ showVideo: true }) }}>
                                    <Image fit="contain" src="images/overview/VideoImage.png" />
                                </Box>
                            </Box>
                            <Text>{parse(site.localizedStrings['ExternalPages.Overview_Banner_Footnotes'])}</Text>
                        </Box>
                        <Box direction="row-responsive" id="top">
                            <ThemeContext.Extend value={{
                                button: {
                                    size: {
                                        small: {
                                            border: {
                                                radius: '0px'
                                            }
                                        },
                                        medium: {
                                            border: {
                                                radius: '0px'
                                            }
                                        },
                                        large: {
                                            border: {
                                                radius: '0px'
                                            }
                                        }
                                    },
                                    border: {
                                        radius: '0px'
                                    }
                                }
                            }} >
                                <Button label={site.localizedStrings['ExternalPages.Overview_WhatsNew']} size="large"
                                    className={classNames({ "nav-btn": true, "active": this.state.activeSection === 0 })}
                                    onClick={() => { this.scrollIntoView('whats-new') }} />
                                <Button label={site.menuStrings['ExternalPages.Overview_GetCertified']} size="large"
                                    className={classNames({ "nav-btn": true, "active": this.state.activeSection === 1 })}
                                    onClick={() => { this.scrollIntoView('get-certified') }} />
                                <Button label={site.localizedStrings['ExternalPages.Overview_UpskillTraining']} size="large"
                                    className={classNames({ "nav-btn": true, "active": this.state.activeSection === 2 })}
                                    onClick={() => { this.scrollIntoView('upskill-training') }} />
                                <Button label={site.menuStrings['ExternalPages.Overview_MoreLearning']} size="large"
                                    className={classNames({ "nav-btn": true, "active": this.state.activeSection === 3 })}
                                    onClick={() => { this.scrollIntoView('more-learning') }} />
                            </ThemeContext.Extend>
                        </Box>
                        <Box pad={{ left: "small" }} gap="large" >
                            <Box id="whats-new" gap="small">
                                <Heading textAlign="center" fill level='1' >{(site.localizedStrings['ExternalPages.Overview_WhatsNew'] ?? "")}</Heading>
                                <Box direction="row-responsive" gap="medium" pad={{ bottom: "medium" }} justify="center">
                                    {this.props.overview.data?.map((whatsNew, index) => {
                                        let url = whatsNew.url.replace(/http(s)*:\/\/certification-learning\.hpe\.com\/(tr|ext)/, localUrl);
                                        let target = url.indexOf(localUrl) === -1 || url.indexOf(`${localUrl}/documents`) !== -1 ? "_blank" : "_self";

                                        return <Box key={`whats-new-${index}`} border={{ color: borderColors[index], size: "medium" }} background="white"
                                            pad="small" width="medium" className="whats-new">
                                            <Heading level="4">{whatsNew.headline}</Heading>
                                            <Paragraph fill>{parse(whatsNew.text)}</Paragraph>
                                            {(url != '') && <Anchor href={url} color="brand" size="medium" target={target}
                                                onClick={() => { this.onWhatsNewClicked(whatsNew.headline) }}
                                                label={<Text>{whatsNew.anchorText} <LinkNext color="brand" size="small" /></Text>} />}
                                        </Box>
                                    })}
                                </Box>
                            </Box>
                            <Box id="section2" gap="xsmall">
                                <Heading textAlign="center" fill level='2' >
                                    {(site.localizedStrings['ExternalPages.Overview_Section2'] ?? "")}
                                </Heading>
                                <Box width={{ max: "xlarge" }} alignSelf="center">
                                    <Paragraph textAlign="center" fill>
                                        {site.localizedStrings['ExternalPages.Overview_Section2_Statement']}
                                    </Paragraph>
                                </Box>
                                <Box width="medium" alignSelf="center">
                                    <Button primary color="brand" href={`${localUrl}/certifications-advantage`}
                                        label={<Text color="white">{site.localizedStrings['ExternalPages.Overview_Section2_Button_Label']}</Text>} />
                                </Box>
                            </Box>
                            <Box id="get-certified" direction="row-responsive" gap="medium" pad="medium" >
                                <Box pad="medium" alignSelf="center" gap="small" width="large" justify="center">
                                    <Heading fill level='2' >{(site.menuStrings['ExternalPages.Overview_GetCertified'] ?? "")}</Heading>
                                    <Paragraph fill>{site.localizedStrings['ExternalPages.Overview_GetCertified_Statement']}</Paragraph>
                                    <ul>
                                        <li>
                                            <Anchor label={site.localizedStrings['ExternalPages.Overview_GetCertified_Statement_Anchor1']}
                                                href={`${localUrl}/technical-certifications`} color="brand" size="medium" />
                                        </li>
                                        <li>
                                            <Anchor label={site.localizedStrings['ExternalPages.Overview_GetCertified_Statement_Anchor2']}
                                                href={`${localUrl}/technical-certifications-networking`} color="brand" size="medium" />
                                        </li>
                                        <li>
                                            <Anchor label={site.localizedStrings['ExternalPages.Overview_GetCertified_Statement_Anchor3']}
                                                href={`${localUrl}/sales-certifications`} color="brand" size="medium" />
                                        </li>
                                        <li>
                                            <Anchor label={site.localizedStrings['ExternalPages.Overview_GetCertified_Statement_Anchor4']}
                                                href={`${localUrl}/credentials`} color="brand" size="medium" />
                                        </li>
                                    </ul>
                                    <Box direction="row-responsive" gap="small">
                                        <Box width="small">
                                            <Button primary color="brand" href={`${localUrl}/technical-certifications/get-certified`}
                                                label={<Text color="white">{site.menuStrings['ExternalPages.Overview_GetCertified']}</Text>} />
                                        </Box>
                                        <Box width="small">
                                            <Button primary color="brand" href='https://psnow.ext.hpe.com/doc/a00098671enw' target="_blank"
                                                label={<Text color="white">{site.localizedStrings['ExternalPages.Overview_Banner_Button1']}</Text>} />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box gap="small" pad="small">
                                    <Image src='images/overview/HPE2022042305380.png'></Image>
                                </Box>
                            </Box>
                            <Box id="upskill-training" fill gap="medium">
                                <Heading textAlign="center" fill level='2' >
                                    {(site.localizedStrings['ExternalPages.Overview_UpskillTraining'] ?? "")}
                                </Heading>
                                <Box width={{ max: "xlarge" }} alignSelf="center">
                                    <Paragraph textAlign="center" fill>
                                        {site.localizedStrings['ExternalPages.Overview_UpskillTraining_Statement']}
                                    </Paragraph>
                                </Box>
                                <Box direction="row-responsive" justify="center" pad={{ top: "medium" }} gap="large">
                                    {/* <Grid columns={["auto", "auto", "auto"]} rows={["auto"]} gap="medium" fill pad={{ bottom: "medium" }}> */}
                                    <Card background={{ size: "cover", image: "url('images/HPE_Gradient_Purple_Blue_Yellow_RGB.jpg')" }}>
                                        <CardBody>
                                            <Heading fill level='3' color="white" >
                                                {(site.localizedStrings['ExternalPages.Overview_UpskillTraining_Box1_Title'] ?? "")}
                                            </Heading>
                                            <Paragraph color="white">
                                                {site.localizedStrings['ExternalPages.Overview_UpskillTraining_Box1_Statement']}
                                            </Paragraph>
                                            <Anchor label={site.localizedStrings['ExternalPages.Overview_UpskillTraining_Box1_Link']}
                                                href={`${localUrl}/trainingCalendar`} color="white" />
                                        </CardBody>
                                    </Card>
                                    <Card background={{ size: "cover", image: "url('images/HPE_Gradient_Purple_Magenta_Yellow_RGB.jpg')" }}>
                                        <CardBody>
                                            <Heading fill level='3' color="white">
                                                {(site.localizedStrings['ExternalPages.Overview_UpskillTraining_Box2_Title'] ?? "")}
                                            </Heading>
                                            <Paragraph color="white">
                                                {site.localizedStrings['ExternalPages.Overview_UpskillTraining_Box2_Statement']}
                                            </Paragraph>
                                            <Anchor label={site.localizedStrings['ExternalPages.Overview_UpskillTraining_Box2_Link']}
                                                color="white" href={`${localUrl}/learningPartners`} />
                                        </CardBody>
                                    </Card>
                                    <Card background={{ size: "cover", image: "url('images/HPE_Gradient_Orange_Yellow_Green_RGB.jpg')" }}>
                                        <CardBody>
                                            <Heading fill level='3' color="white" >
                                                {(site.localizedStrings['ExternalPages.Overview_UpskillTraining_Box3_Title'] ?? "")}
                                            </Heading>
                                            <Paragraph color="white">
                                                {site.localizedStrings['ExternalPages.Overview_UpskillTraining_Box3_Statement']}
                                            </Paragraph>
                                            <Box direction="row-responsive" gap="small">
                                                <Anchor label={site.localizedStrings['ExternalPages.Overview_UpskillTraining_Box3_Link']}
                                                    color="white" href={`${localUrl}/hpepress`} />
                                                <Anchor href="http://hpelabsonline.com" target="_blank" color="white"
                                                    label={site.menuStrings['ExternalPages.SelfDirected_Lab_Vouchers_Label']} />
                                            </Box>
                                        </CardBody>
                                    </Card>
                                    {/* </Grid> */}
                                </Box>
                            </Box>
                            <Box id="more-learning" fill gap="medium" pad={{ bottom: "small" }}>
                                <Box fill gap="medium">
                                    <Heading textAlign="center" fill level='2'>{(site.menuStrings['ExternalPages.Overview_MoreLearning'] ?? "")}</Heading>
                                    <Box width={{ max: "xlarge" }} alignSelf="center">
                                        <Paragraph fill textAlign="center">{site.localizedStrings['ExternalPages.Overview_MoreLearning_Statement']}</Paragraph>
                                    </Box>
                                    <Box direction="row-responsive" gap="small" justify="center">
                                        <Box background="#7630EA" pad="5px">
                                            <Text color="white" weight="bold">{site.localizedStrings['ExternalPages.Overview_MoreLearning_Partners_Label']}</Text>
                                        </Box>
                                        <Box background="#7FF9E2" pad="5px">
                                            <Text weight="bold">{site.localizedStrings['ExternalPages.Overview_MoreLearning_Customers_Label']}</Text>
                                        </Box>
                                        <Box background="#FF8300" pad="5px">
                                            <Text color="white" weight="bold">{site.localizedStrings['ExternalPages.Overview_MoreLearning_Employees_Label']}</Text>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box fill gap="small">
                                    <Box direction="row-responsive" align="center" gap="small">
                                        <Box fill="horizontal" border />
                                        <Text size="large" style={{ whiteSpace: "nowrap" }} >
                                            {(site.localizedStrings['ExternalPages.Overview_MoreLearning_Communities_Label'] ?? "")}
                                        </Text>
                                        <Box fill="horizontal" border />
                                    </Box>
                                    <Grid rows="auto" columns={["auto", "auto"]} gap="medium">
                                        <Box direction="row-responsive" border={{ color: "#F5F5F5", size: "large" }} pad="small" gap="small">
                                            <Box direction="row" width="medium">
                                                <Box gap="2px" pad={{ top: "medium" }} style={{ zIndex: 1000 }} width={{ min: "20px" }}>
                                                    <Box background="#7630EA" border={{ side: 'all', color: '#7630EA', size: "medium" }} width="20px"></Box>
                                                    <Box background="#FF8300" border={{ side: 'all', color: '#FF8300', size: "medium" }} width="20px"></Box>
                                                    <Box background="#7FF9E2" border={{ side: 'all', color: '#7FF9E2', size: "medium" }} width="20px"></Box>
                                                </Box>
                                                <Box margin={{ left: "-10px" }}>
                                                    <Image fit="contain" src='images/overview/HPE20160726036_1600_0_72_RGB.jpg' />
                                                </Box>
                                            </Box>
                                            <Box align="center" justify="center" pad={{ right: "small" }} fill >
                                                <Text textAlign="center" size="large" weight="bold">
                                                    {site.localizedStrings['ExternalPages.Overview_MoreLearning_Communities_Box1_Title']}
                                                </Text>
                                                <Paragraph fill textAlign="center">
                                                    {site.localizedStrings['ExternalPages.Overview_MoreLearning_Communities_Box1_Statement']
                                                    }</Paragraph>
                                                <Anchor href="https://techpro.hpe.com/about.aspx" color="brand" size="medium" target="_blank"
                                                    label={<Text>{site.localizedStrings['ExternalPages.JoinNow_Label']} <LinkNext color="brand" size="small" /></Text>} />
                                            </Box>
                                        </Box>
                                        <Box direction="row-responsive" border={{ color: "#F5F5F5", size: "large" }} pad="small" gap="small">
                                            <Box direction="row" width="medium">
                                                <Box gap="2px" pad={{ top: "medium" }} style={{ zIndex: 1000 }}>
                                                    <Box background="#7630EA" border={{ side: 'all', color: '#7630EA', size: "medium" }} width="20px"></Box>
                                                    <Box background="#FF8300" border={{ side: 'all', color: '#FF8300', size: "medium" }} width="20px"></Box>
                                                    <Box background="#7FF9E2" border={{ side: 'all', color: '#7FF9E2', size: "medium" }} width="20px"></Box>
                                                </Box>
                                                <Box margin={{ left: "-10px" }}>
                                                    <Image fit="contain" src='images/overview/HPE20180226088_1600_0_72_RGB.jpg' />
                                                </Box>
                                            </Box>
                                            <Box align="center" justify="center" pad={{ right: "small" }} fill >
                                                <Text textAlign="center" size="large" weight="bold">
                                                    {site.menuStrings['ExternalPages.Aruba_Airheads_Label']}
                                                </Text>
                                                <Paragraph fill textAlign="center">
                                                    {site.localizedStrings['ExternalPages.Overview_MoreLearning_Communities_Box2_Statement']
                                                    }</Paragraph>
                                                <Anchor href="https://community.arubanetworks.com/home" color="brand" size="medium" target="_blank"
                                                    label={<Text>{site.localizedStrings['ExternalPages.JoinNow_Label']} <LinkNext color="brand" size="small" /></Text>} />
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Box direction="row" gap="xsmall" justify="center" fill>
                                        <Anchor href="https://www.hpe.com/us/en/communities.html" color="brand" size="medium" target="_blank"
                                            label={<Text>{site.localizedStrings['ExternalPages.Overview_MoreCommunities_Label']} <LinkNext color="brand" size="small" /></Text>} />
                                    </Box>
                                </Box>
                                <Box fill gap="small">
                                    <Box direction="row" align="center" gap="small">
                                        <Box fill="horizontal" border />
                                        <Text size="large" textAlign="center" style={{ whiteSpace: "nowrap" }} >
                                            {(site.localizedStrings['ExternalPages.Overview_MoreLearning_EnablementPrograms_Label'] ?? "")}
                                        </Text>
                                        <Box fill="horizontal" border />
                                    </Box>
                                    <Grid rows="auto" columns={["auto", "auto"]} gap="medium">
                                        <Box direction="row-responsive" border={{ color: "#F5F5F5", size: "large" }} pad="small" gap="small">
                                            <Box direction="row" width="medium">
                                                <Box gap="2px" pad={{ top: "medium" }} style={{ zIndex: 1000 }}>
                                                    <Box background="#7630EA" border={{ side: 'all', color: '#7630EA', size: "medium" }} width="20px"></Box>
                                                    <Box background="#FF8300" border={{ side: 'all', color: '#FF8300', size: "medium" }} width="20px"></Box>
                                                </Box>
                                                <Box margin={{ left: "-10px" }}>
                                                    <Image fit="contain" src='images/overview/HPE2022042509184_200 x 200.jpg' />
                                                </Box>
                                            </Box>
                                            <Box align="center" justify="center" pad={{ right: "small" }} fill >
                                                <Text textAlign="center" size="large" weight="bold">
                                                    {site.localizedStrings['ExternalPages.Overview_MoreLearning_Enablement_Box1_Title']}
                                                </Text>
                                                <Paragraph fill textAlign="center">
                                                    {site.localizedStrings['ExternalPages.Overview_MoreLearning_Enablement_Box1_Statement']
                                                    }</Paragraph>
                                                <Anchor href="https://salespro.hpe.com/" color="brand" size="medium" target="_blank"
                                                    label={<Text>{site.localizedStrings['ExternalPages.JoinNow_Label']} <LinkNext color="brand" size="small" /></Text>} />
                                            </Box>
                                        </Box>
                                        <Box direction="row-responsive" border={{ color: "#F5F5F5", size: "large" }} pad="small" gap="small">
                                            <Box direction="row" width="medium">
                                                <Box gap="2px" pad={{ top: "medium" }} style={{ zIndex: 1000 }}>
                                                    <Box background="#7630EA" border={{ side: 'all', color: '#7630EA', size: "medium" }} width="20px"></Box>
                                                </Box>
                                                <Box margin={{ left: "-10px" }}>
                                                    <Image fit="contain" src='images/overview/GettyImages-651423061_200x200.jpg' />
                                                </Box>
                                            </Box>
                                            <Box align="center" justify="center" pad={{ right: "small" }} fill >
                                                <Text textAlign="center" size="large" weight="bold">
                                                    {site.localizedStrings['ExternalPages.Overview_MoreLearning_Enablement_Box2_Title']}
                                                </Text>
                                                <Paragraph fill textAlign="center">
                                                    {site.localizedStrings['ExternalPages.Overview_MoreLearning_Enablement_Box2_Statement']
                                                    }</Paragraph>
                                                <Anchor href="https://partner.hpe.com/article-display-page?id=483848" color="brand" size="medium" target="_blank"
                                                    label={<Text>{site.localizedStrings['ExternalPages.GoNow_Label']} <LinkNext color="brand" size="small" /></Text>} />
                                            </Box>
                                        </Box>
                                        <Box direction="row-responsive" border={{ color: "#F5F5F5", size: "large" }} pad="small" gap="small">
                                            <Box direction="row" width="medium">
                                                <Box gap="2px" pad={{ top: "medium" }} style={{ zIndex: 1000 }}>
                                                    <Box background="#7FF9E2" border={{ side: 'all', color: '#7FF9E2', size: "medium" }} width="20px"></Box>
                                                </Box>
                                                <Box margin={{ left: "-10px" }}>
                                                    <Image fit="contain" src='images/overview/Stocksy_2031140_200x200.jpg' />
                                                </Box>
                                            </Box>
                                            <Box align="center" justify="center" pad={{ right: "small" }} fill >
                                                <Text textAlign="center" size="large" weight="bold">
                                                    {site.localizedStrings['ExternalPages.Overview_MoreLearning_Enablement_Box3_Title']}
                                                </Text>
                                                <Paragraph fill textAlign="center">
                                                    {site.localizedStrings['ExternalPages.Overview_MoreLearning_Enablement_Box3_Statement']
                                                    }</Paragraph>
                                                <Anchor href="https://education.hpe.com/ww/en/training/index.html" color="brand" size="medium" target="_blank"
                                                    label={<Text>{site.localizedStrings['ExternalPages.GoNow_Label']} <LinkNext color="brand" size="small" /></Text>} />
                                            </Box>
                                        </Box>
                                        <Box direction="row-responsive" border={{ color: "#F5F5F5", size: "large" }} pad="small" gap="small">
                                            <Box direction="row" width="medium">
                                                <Box gap="2px" pad={{ top: "medium" }} style={{ zIndex: 1000 }}>
                                                    <Box background="#7FF9E2" border={{ side: 'all', color: '#7FF9E2', size: "medium" }} width="20px"></Box>
                                                </Box>
                                                <Box margin={{ left: "-10px" }}>
                                                    <Image fit="contain" src='images/overview/HPE20160512173_1600_0_72_RGB.jpg' />
                                                </Box>
                                            </Box>
                                            <Box align="center" justify="center" pad={{ right: "small" }} fill >
                                                <Text textAlign="center" size="large" weight="bold">
                                                    {site.localizedStrings['ExternalPages.Overview_MoreLearning_Enablement_Box4_Title']}
                                                </Text>
                                                <Paragraph fill textAlign="center">
                                                    {site.localizedStrings['ExternalPages.Overview_MoreLearning_Enablement_Box4_Statement']
                                                    }</Paragraph>
                                                <Anchor href="https://www.arubanetworks.com/support-services/training-services/" color="brand" size="medium" target="_blank"
                                                    label={<Text>{site.localizedStrings['ExternalPages.GoNow_Label']} <LinkNext color="brand" size="small" /></Text>} />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Box>
                                <Box fill gap="small">
                                    <Box direction="row" align="center" gap="small">
                                        <Box fill="horizontal" border />
                                        <Text size="large" textAlign="center" style={{ whiteSpace: "nowrap" }} >
                                            {(site.localizedStrings['ExternalPages.Overview_MoreLearning_MoreCertifications_Label'] ?? "")}
                                        </Text>
                                        <Box fill="horizontal" border />
                                    </Box>
                                    <Grid rows="auto" columns={["auto", "auto"]} gap="medium">
                                        <Box direction="row-responsive" border={{ color: "#F5F5F5", size: "large" }} pad="small" gap="small">
                                            <Box direction="row" width="medium">
                                                <Box gap="2px" pad={{ top: "medium" }} style={{ zIndex: 1000 }}>
                                                    <Box background="#7630EA" border={{ side: 'all', color: '#7630EA', size: "medium" }} width="20px"></Box>
                                                    <Box background="#FF8300" border={{ side: 'all', color: '#FF8300', size: "medium" }} width="20px"></Box>
                                                    <Box background="#7FF9E2" border={{ side: 'all', color: '#7FF9E2', size: "medium" }} width="20px"></Box>
                                                </Box>
                                                <Box margin={{ left: "-10px" }}>
                                                    <Image fit="contain" src='images/overview/AdobeStock_278814909_200x200.jpg' />
                                                </Box>
                                            </Box>
                                            <Box align="center" justify="center" pad={{ right: "small" }} fill >
                                                <Text textAlign="center" size="large" weight="bold">
                                                    {site.localizedStrings['ExternalPages.Overview_MoreLearning_MoreCerts_Box1_Title']}
                                                </Text>
                                                <Paragraph fill textAlign="center">
                                                    {site.localizedStrings['ExternalPages.Overview_MoreLearning_MoreCerts_Box1_Statement']
                                                    }</Paragraph>
                                                <Anchor href="https://hpepress.hpe.com/" color="brand" size="medium" target="_blank"
                                                    label={<Text>{site.localizedStrings['ExternalPages.GoNow_Label']} <LinkNext color="brand" size="small" /></Text>} />
                                            </Box>
                                        </Box>
                                        <Box direction="row-responsive" border={{ color: "#F5F5F5", size: "large" }} pad="small" gap="small">
                                            <Box direction="row" width="medium">
                                                <Box gap="2px" pad={{ top: "medium" }} style={{ zIndex: 1000 }}>
                                                    <Box background="#7630EA" border={{ side: 'all', color: '#7630EA', size: "medium" }} width="20px"></Box>
                                                    <Box background="#FF8300" border={{ side: 'all', color: '#FF8300', size: "medium" }} width="20px"></Box>
                                                    <Box background="#7FF9E2" border={{ side: 'all', color: '#7FF9E2', size: "medium" }} width="20px"></Box>
                                                </Box>
                                                <Box margin={{ left: "-10px" }}>
                                                    <Image fit="contain" src='images/overview/HPE20160526043_1600_0_72_RGB.jpg' />
                                                </Box>
                                            </Box>
                                            <Box align="center" justify="center" pad={{ right: "small" }} fill >
                                                <Text textAlign="center" size="large" weight="bold">
                                                    {site.localizedStrings['Datacard.Learning_Center_Label']}
                                                </Text>
                                                <Paragraph fill textAlign="center">
                                                    {site.localizedStrings['ExternalPages.Overview_MoreLearning_MoreCerts_Box2_Statement']
                                                    }</Paragraph>
                                                <Anchor href="http://hpe-external.sabacloud.com/" color="brand" size="medium" target="_blank"
                                                    label={<Text>{site.localizedStrings['ExternalPages.GoNow_Label']} <LinkNext color="brand" size="small" /></Text>} />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                        {this.state.showVideo && <Layer>
                            <Header justify='between' background="brand">
                                <Button alignSelf='end' icon={<FormClose />} onClick={this.onCloseVideo} />
                            </Header>
                            <Box height='large' width='xlarge'>
                                <iframe width="100%" height="100%" src='https://www.youtube.com/embed/W4YcyN7Oes4?autoplay=1'
                                    frameBorder="0" allowFullScreen title="HPE technical certifications"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
                            </Box>
                        </Layer>}
                    </Box>}
                </BasePage>
            )}
        </ResponsiveContext.Consumer>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(withRouter(Overview as any));
